<template>
  <div class="s-thanks-bar">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.14 45.14"
      aria-hidden="true"
      focusable="false"
      role="img"
      class="s-thanks-bar__ico"
    >
      <path
        fill="#5BBBA1"
        d="M22.57 0C10.1 0 0 10.1 0 22.57s10.1 22.57 22.57 22.57 22.57-10.1 22.57-22.57C45.12 10.11 35.03.01 22.57 0zm12.61 16.63L20.76 30.94c-.85.87-2.23.89-3.11.06l-7.64-6.96c-.89-.84-.97-2.24-.17-3.17.84-.91 2.26-.96 3.16-.12l6.05 5.54 12.9-12.9c.9-.88 2.35-.85 3.22.05.87.9.87 2.3.01 3.19z"
      />
    </svg>
    <h1 class="s-thanks-bar__title">Vaše rezervace byla úspěšně vytvořena.</h1>
    <p class="s-thanks-bar__subtitle">Děkujeme.</p>
    <h2 class="s-u-visualy-hidden">Přihlasili jste se na následující test</h2>
    <!--div class="s-thanks-bar__cards mb-3 mb-lg-5 justify-content-center">
      <div class="s-thanks-bar__card-item">
        <div class="s-basic-card">
          <h3 class="s-u-visualy-hidden">Osoba 1</h3>
          <p class="s-basic-card__title">Osoba 1</p>
          <p class="s-basic-card__text-ico">
            <span class="d-block">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user-alt"
                class="d-block"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="13.3"
                height="13.3"
              >
                <path
                  fill="#F0AB69"
                  d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
                ></path>
              </svg>
            </span>
            <span class="s-basic-card__text"> Jméno přijmení </span>
          </p>
        </div>
      </div>
      <div class="s-thanks-bar__card-item">
        <div class="s-basic-card">
          <h3 class="s-u-visualy-hidden">Osoba 2</h3>
          <p class="s-basic-card__title">Osoba 2</p>
          <p class="s-basic-card__text-ico">
            <span class="d-block">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user-alt"
                class="d-block"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="13.3"
                height="13.3"
              >
                <path
                  fill="#F0AB69"
                  d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
                ></path>
              </svg>
            </span>
            <span class="s-basic-card__text"> Jméno přijmení </span>
          </p>
        </div>
      </div>
    </div>
    <div class="s-thanks-bar__cards">
      <div class="s-thanks-bar__card-item">
        <div class="s-basic-card">
          <h3 class="s-u-visualy-hidden">Místo testování</h3>
          <p class="s-basic-card__title">Praha (Parkoviště Sokol Vinohrady)</p>
          <p class="s-basic-card__text-ico">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.2 16.3"
                width="12.2"
                height="16.3"
                aria-hidden="true"
                focusable="false"
                role="img"
                class="d-block"
              >
                <path
                  fill="#F0AB69"
                  d="M5.5 16C.9 9.3 0 8.6 0 6.1 0 2.7 2.7 0 6.1 0s6.1 2.7 6.1 6.1c0 2.5-.9 3.2-5.5 9.9-.2.3-.7.4-1.1.2 0-.1-.1-.2-.1-.2zm.6-7.3c1.4 0 2.5-1.1 2.5-2.5S7.5 3.6 6.1 3.6 3.6 4.7 3.6 6.1s1.1 2.6 2.5 2.6z"
                />
              </svg>
            </span>
            <span class="s-basic-card__text"> Polská 2400/1a, 120 00 Praha 2 </span>
          </p>
        </div>
      </div>
      <div class="s-thanks-bar__card-item">
        <div class="s-basic-card">
          <h3 class="s-u-visualy-hidden">Produkt</h3>
          <p class="s-basic-card__title">PCR Test (Samoplátce)</p>
          <p class="s-basic-card__text-ico">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 16"
                width="12"
                height="16"
                aria-hidden="true"
                focusable="false"
                role="img"
                class="d-block"
              >
                <path
                  fill="#F0AB69"
                  d="M11.8 3.3L8.7.2C8.6.1 8.4 0 8.2 0H8v4h4v-.2c0-.2-.1-.4-.2-.5zM7 4.2V0H.7C.3 0 0 .3 0 .7v14.5c0 .4.3.8.7.8h10.5c.4 0 .7-.3.7-.7V5H7.7c-.4 0-.7-.3-.7-.8zm2.2 5.5v.5c0 .3-.2.5-.5.5h-2v2c0 .3-.2.5-.5.5h-.5c-.3 0-.5-.2-.5-.5v-2h-2c-.3 0-.5-.2-.5-.5v-.5c0-.3.2-.5.5-.5h2v-2c0-.3.2-.5.5-.5h.5c.3 0 .5.2.5.5v2h2c.3 0 .5.3.5.5z"
                />
              </svg>
            </span>
            <span class="s-basic-card__text"> RT-PCR test SARS-CoV-2 </span>
          </p>
        </div>
      </div>
      <div class="s-thanks-bar__card-item">
        <div class="s-basic-card">
          <h3 class="s-u-visualy-hidden">Termín</h3>
          <p class="s-basic-card__title">Úterý 24.8.2021</p>
          <p class="s-basic-card__text-ico">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 13.3 16"
                width="13.3"
                height="16"
                aria-hidden="true"
                focusable="false"
                role="img"
              >
                <path
                  fill="#F0AB69"
                  d="M0 14.5c0 .8.6 1.5 1.4 1.5h10.4c.8 0 1.4-.7 1.4-1.5V6H0v8.5zm1.9-6c0-.3.2-.5.5-.5h8.5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5H2.4c-.3 0-.5-.2-.5-.5v-2zm10-6.5h-1.4V.5c-.1-.3-.3-.5-.5-.5H9c-.3 0-.5.2-.5.5V2H4.7V.5c0-.3-.2-.5-.4-.5h-1c-.2 0-.5.2-.5.5V2H1.4C.6 2 0 2.7 0 3.5V5h13.3V3.5c0-.8-.6-1.5-1.4-1.5z"
                />
              </svg>
            </span>
            <span class="s-basic-card__text"> Odběr v čase mezi 8:00 a 8:15 </span>
          </p>
        </div>
      </div>
    </div !-->
    <p class="s-thanks-bar__text">Nyní můžete zaplatit rezervaci kartou, nebo vytvořit novou rezervaci.</p>
    <div class="s-thanks-bar__btns">
      <div class="s-thanks-bar__btn-box mb-3 mb-sm-0">
        <a href="javascript:void(0)" class="s-btn w-100"> Zaplatit kartou </a>
      </div>
      <div class="s-thanks-bar__btn-box">
        <a href="" class="s-btn s-btn--tertiary w-100"> Vytvořit novou rzervaci </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationCompleted.vue',
};
</script>

<style scoped>

</style>
